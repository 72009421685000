import React, { useState, useEffect } from "react";

export const Input = ({
  input,
  label,
  meta: { touched, error },
  config,
  placeholder,
  widthStyle,
  type,
  style,
  maxLength,
  data,
  value,
  ...props
}) => {
  widthStyle = widthStyle ? widthStyle : "";

  const validationSpan =
    touched && error ? (
      <span className="error_msg text-danger">{error}</span>
    ) : null;

  const [inputClass, setInputClass] = useState("form-group");
  useEffect(() => {
    if (input.value != "") {
      setInputClass("form-group floating-input");
    } else {
      setInputClass("form-group");
    }
  }, [input.value]);
  return (
    // <div className={widthStyle}>
    <div className={inputClass} style={style}>
      <input
        className={"form-control"}
        placeholder={placeholder}
        {...input}
        {...config}
        onChange={(value) => {
          input.onChange(value);
        }}
        onFocus={() => {
          setInputClass("form-group floating-input");
        }}
        type={type}
        // value={data}
        maxLength={maxLength ? null : 50}
        autoComplete="off"
      />
      {label && <label>{label}</label>}
      {validationSpan}
    </div>
    // </div>
  );
};
