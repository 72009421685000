import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { reduxForm, Field, change as onChangeForm } from "redux-form";
import "./style.scss";
import validator from "./validator";
import moment from "moment-timezone";
import { connect } from "react-redux";
import Switch from "react-switch";
import { Gif } from "@giphy/react-components";
import { DnsSharp } from "@material-ui/icons";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Collapsible from "react-collapsible";
import { CustomPagination } from "../../../../components/atoms/pagination";
const {
  defaultConfig: { LOCATION },
} = require(`../../../../config/default`);
const { Form } = require(`../../../../components/atoms/form`);
const { InputSubmit } = require(`../../../../components/atoms/input-submit`);
const { Input } = require(`../../../../components/atoms/input`);
const { TextArea } = require(`../../../../components/atoms/text-area`);
const { Select } = require(`../../../../components/atoms/select`);
const { KeyboardDateTimePickerr } = require(`../../../../components/atoms/date-time-picker`);
const { TimePickerInputField } = require(`../../../../components/atoms/field-time-picker`);
const {
  onSubmitFail,
  diff_minutes,
  changeEndDate,
  convertToESTTimeZone,
  convertTimeForEdit,
} = require(`../../../../helpers`);
const { STRINGS } = require(`../../../../shared/constants/${LOCATION}/strings`);
const { ROUTES, PAGE_TITLES, IBAR_ANNOUNCEMENTS, IBAR_TRIVIA } = require(`../../../../shared/constants`);
const { SnackbarWrapper } = require(`../../../../components/molecules/snackbar-wrapper`);
const { RadioButtons } = require(`../../../../components/atoms/radio-button`);
const { CustomFileDrop } = require(`../../../../components/cells/custom-filedrop`);
const { SelectGiphModal } = require(`../../../../components/cells/SelectGiphModal`);
const { DecisionPopup } = require(`../../../../components/atoms/decision-popup`);
const { IbarForm } = require("./ibarDialog");
const { TriviaForm } = require("./triviaDialog");
moment.tz.setDefault("America/New_York");

let initialParty = {};

const WatchPartyForm = ({
  handleSubmit = () => {},
  initialize,
  allPlatforms,
  allLeagues,
  addWatchParty,
  getPlatforms,
  getLeagues,
  history,
  userToken,
  getWatchPartyVideos,
  allWatchPartyVideosList,
  uploadFile = () => {},
  updateParty,
  cloneWatchParty,
  watchPartyList,
  getWatchPartyInfo,
  onChangeForm,
  announcementList,
  getAnnouncementListing,
  deleteAnnouncement,
  iTriviaList,
}) => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  const [fields, setFields] = useState({
    startTime: null,
    sports: "",
    league: "",
    platform: "",
    contentLength: "",
    endTime: null,
    show: "",
    video: "",
    videoName: "",
    dialogTitle: "",
    dialogUrl: "",
    dialogText: "",
    gifResult: "",
    primaryHexCode: "",
    secondaryHexCode: "",
    password: "",
  });

  const [selectedWatchPartyVideoOption, setSelectedWatchPartyVideoOption] = React.useState("Select Video");
  const [openSnackBar, setOpenSnackbar] = useState(false);
  const [setvideoName, updateVideoName] = useState();
  const [currentWatchParty, updateCurrentWatchParty] = useState();
  const [editMode, updateEditMode] = useState(false);
  const [cloneMode, updateCloneMode] = useState(false);
  const [showNotFoundWatchPartyIdMessage, updateNotFoundWatchPartyIdMessage] = useState();
  const [watchPartyForUpdate, updateWatchPartyForUpdate] = useState();
  const [snackbarData, setSnackBarData] = useState({
    variant: "",
    message: "",
  });
  const onChangeField = (type, value) => {
    setFields({ ...fields, [type]: value });
  };
  const [selectedLeague, setSelectedLeague] = useState();
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [selectedSport, setSelectedSport] = useState("");
  const [watchPartyVideos, setWatchPartyVideos] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [leagues, setLeagues] = useState([]);
  const [isCustom, updateIsCustom] = useState(false);
  const [videoFileData, updateVideoFileData] = useState();
  const [showRemoveVideoOption, updateRemoveVideoOption] = useState(false);
  const [modalGif, setModalGif] = useState([{ url: "" }, { url: "" }, { url: "" }, { url: "" }]);
  const [searchGiphText, set_searchGiphText] = useState("");
  const [minGifError, set_minGifError] = useState("");
  const [defaultKeyword, set_defaultKeyword] = useState("");
  const [iBarPopupVisible, setIbarPopupVisible] = useState(false);
  const [triviaPopupVisible, setTriviaPopupVisible] = useState(false);
  const [openDeleteAnnouncementPopup, setDeleteAnnouncementPopup] = useState(false);
  const [selectedAnnouncementId, setselectedAnnouncementId] = useState(null);

  useEffect(() => {
    if (watchPartyForUpdate && watchPartyForUpdate.videoInfo && watchPartyForUpdate.videoInfo.name && editMode) {
      updateRemoveVideoOption(true);
    }
    if (watchPartyForUpdate) {
      setFields({
        ...fields,
        watchPartyId: watchPartyForUpdate._id,
        show: !!cloneMode ? "" : watchPartyForUpdate && watchPartyForUpdate.contentName,
        isHidden: watchPartyForUpdate.isHidden,
        sports: watchPartyForUpdate.sports === true ? "Yes" : "No",
        league: watchPartyForUpdate && watchPartyForUpdate.leagueInfo && watchPartyForUpdate.leagueInfo._id,
        platform: watchPartyForUpdate && watchPartyForUpdate.platformInfo && watchPartyForUpdate.platformInfo._id,
        endTime:
          watchPartyForUpdate &&
          watchPartyForUpdate.endTime &&
          new Date(convertTimeForEdit(watchPartyForUpdate.endTime)),
        startTime:
          watchPartyForUpdate &&
          watchPartyForUpdate.startTime &&
          new Date(convertTimeForEdit(watchPartyForUpdate.startTime)),
        contentLength: watchPartyForUpdate && watchPartyForUpdate.contentLength,
        videoName: watchPartyForUpdate && watchPartyForUpdate.videoInfo && watchPartyForUpdate.videoInfo.name,
        video: watchPartyForUpdate && watchPartyForUpdate.videoInfo && watchPartyForUpdate.videoInfo.url,
        dialogTitle: watchPartyForUpdate && watchPartyForUpdate.shareDialogTitle,
        dialogText: watchPartyForUpdate && watchPartyForUpdate.inviteText,
        dialogUrl: watchPartyForUpdate && watchPartyForUpdate.shareDialogUrl,
        password: watchPartyForUpdate && watchPartyForUpdate.password,
      });
      set_defaultKeyword(watchPartyForUpdate && watchPartyForUpdate.defaultGifKeyword);
      setModalGif(
        (watchPartyForUpdate &&
          watchPartyForUpdate.defaultGifs &&
          watchPartyForUpdate.defaultGifs.length &&
          watchPartyForUpdate.defaultGifs) ||
          modalGif
      );
    }
    if (watchPartyForUpdate && watchPartyForUpdate._id) {
      getAnnouncementListing(watchPartyForUpdate._id);
    }
  }, [watchPartyForUpdate]);

  useEffect(() => {
    let path = history.location.pathname;
    let watch_party_id = query.get("watch_party_id");
    if ((path == "/edit-watch-party" || path == "/clone-watch-party") && watch_party_id) {
      if (path == "/edit-watch-party") {
        updateEditMode(true);
      } else {
        updateCloneMode(true);
      }
      getWatchPartyInfo(
        watch_party_id,
        (response) => {
          updateWatchPartyForUpdate(response);
          setSnackBarData({
            variant: response.status ? "success" : "error",
            message: response.msg,
          });
          //  setOpenSnackbar(true)
        },
        (error) => {
          setSnackBarData({
            variant: error.status ? "success" : "error",
            message: error.msg,
          });
          setOpenSnackbar(true);
        }
      );
    } else if (path == "/edit-watch-party") {
      updateNotFoundWatchPartyIdMessage(true);
    }
  }, [history && history.location && history.location.pathname]);

  const onsubmit = (credentials) => {
    if (!!videoFileData) {
      uploadFile(
        videoFileData,
        (url) => {
          onChangeField("video", url);
          updateIsCustom(true);
          updateRemoveVideoOption(false);
          !!editMode ? edit_WatchParty(url) : !!cloneMode ? clone_WatchParty(url) : add_WatchParty(credentials, url);
        },
        (error) => {
          setSnackBarData({
            variant: error.status ? "success" : "error",
            message: error.msg,
          });
          setOpenSnackbar(true);
        }
      );
    } else {
      !!editMode ? edit_WatchParty("") : !!cloneMode ? clone_WatchParty("") : add_WatchParty(credentials, "");
    }
  };
  const edit_WatchParty = (url) => {
    let isAllGif = false;
    let isAllGifEmpty = false;
    isAllGif = validateAllUserGifs();
    isAllGifEmpty = validateForEmptyUserGifs();

    if (isAllGif || isAllGifEmpty) {
      set_minGifError(" ");
    } else {
      set_minGifError(STRINGS.ERROR_FOR_MIN_LENGTH_GIFS);
      return;
    }

    let st = fields.startTime && convertToESTTimeZone(new Date(new Date(fields.startTime).setSeconds(0, 0)));
    let et = fields.endTime && convertToESTTimeZone(new Date(new Date(fields.endTime).setSeconds(0, 0)));

    const postData = {
      watchPartyId: fields.watchPartyId,
      contentName: fields.show,
      startTime: st,
      endTime: et,
      isHidden: fields.isHidden,
      sports: fields.sports === "No" ? "false" : "true",
      league: fields.league,
      platform: fields.platform,
      contentLength: fields.contentLength,
      videoUrl: !!url ? url : fields.video,
      isCustom: isCustom,
      videoName: fields.videoName,
      gifs: isAllGif ? modalGif : [],
      defaultGifKeyword: defaultKeyword,
      shareDialogTitle: fields.dialogTitle,
      inviteText: fields.dialogText,
      shareDialogUrl: fields.dialogUrl,
      password: fields.password && fields.password.toLowerCase(),
    };

    if (isAllGif || isAllGifEmpty) {
      updateParty(
        postData,
        (response) => {
          setSnackBarData({
            variant: response.status ? "success" : "error",
            message: response.msg,
          });
          setOpenSnackbar(true);
          history.push(ROUTES.WATCH_PARTY);
        },
        (error) => {
          setSnackBarData({
            variant: error.status ? "success" : "error",
            message: error.msg,
          });
          setOpenSnackbar(true);
        }
      );
    } else {
      console.log("Error in edit");
    }
  };

  const clone_WatchParty = (url) => {
    let isAllGif = false;
    let isAllGifEmpty = false;
    isAllGif = validateAllUserGifs();
    isAllGifEmpty = validateForEmptyUserGifs();

    if (isAllGif || isAllGifEmpty) {
      set_minGifError(" ");
    } else {
      set_minGifError(STRINGS.ERROR_FOR_MIN_LENGTH_GIFS);
      return;
    }
    let st = convertToESTTimeZone(new Date(new Date(fields.startTime).setSeconds(0, 0)));
    let et = convertToESTTimeZone(new Date(new Date(fields.endTime).setSeconds(0, 0)));

    if (fields.show == watchPartyForUpdate.contentName) {
      setSnackBarData({
        variant: "error",
        message: "Kindly select different name for clone watch party.",
      });
      setOpenSnackbar(true);
      return;
    }
    const postData = {
      watchPartyId: fields.watchPartyId,
      contentName: fields.show,
      startTime: st,
      endTime: et,
      isHidden: fields.isHidden,
      sports: fields.sports === "No" ? "false" : "true",
      league: fields.league,
      platform: fields.platform,
      contentLength: fields.contentLength,
      videoUrl: !!url ? url : fields.video,
      isCustom: isCustom,
      videoName: fields.videoName,
      gifs: isAllGif ? modalGif : [],
      defaultGifKeyword: defaultKeyword,
      shareDialogTitle: fields.dialogTitle,
      inviteText: fields.dialogText,
      shareDialogUrl: fields.dialogUrl,
    };

    if (isAllGif || isAllGifEmpty) {
      cloneWatchParty(
        postData,
        (response) => {
          setSnackBarData({
            variant: response.status ? "success" : "error",
            message: response.msg,
          });
          setOpenSnackbar(true);
          history.push(ROUTES.WATCH_PARTY);
        },
        (error) => {
          setSnackBarData({
            variant: error.status ? "success" : "error",
            message: error.msg,
          });
          setOpenSnackbar(true);
        }
      );
    } else {
    }
  };

  useEffect(() => {
    let arr = [];

    allPlatforms &&
      allPlatforms.map((platform) => {
        let obj = { value: platform._id, label: platform.name };
        arr.push(obj);
      });
    setPlatforms(arr);
  }, [allPlatforms]);

  useEffect(() => {
    let arr = [];
    allLeagues &&
      allLeagues.map((platform) => {
        let obj = { value: platform._id, label: platform.name };
        arr.push(obj);
      });
    setLeagues(arr);
  }, [allLeagues]);

  useEffect(() => {
    let arr = [];
    allWatchPartyVideosList &&
      allWatchPartyVideosList.map((platform) => {
        let obj = { label: platform.name, value: platform.videoUrl };
        arr.push(obj);
      });
    setWatchPartyVideos(arr);
  }, [allWatchPartyVideosList]);

  const validateAllUserGifs = () => {
    // to check if 4 gif are selected by user
    if (modalGif) {
      let data = modalGif.every((item) => (!!item.url ? true : false));
      return data;
    }
  };
  const validateForEmptyUserGifs = () => {
    //to check if no gif selected by user
    if (modalGif) {
      let data = modalGif.every((item) => (item.url ? false : true));
      return data;
    }
  };

  const add_WatchParty = (credentials, url) => {
    let isAllGif = false;
    let isAllGifEmpty = false;
    isAllGif = validateAllUserGifs();
    isAllGifEmpty = validateForEmptyUserGifs();

    if (isAllGif || isAllGifEmpty) {
      set_minGifError(" ");
    } else {
      set_minGifError(STRINGS.ERROR_FOR_MIN_LENGTH_GIFS);
      return;
    }

    let st = convertToESTTimeZone(new Date(new Date(fields.startTime).setSeconds(0, 0)));
    let et = convertToESTTimeZone(new Date(new Date(fields.endTime).setSeconds(0, 0)));

    let postData = {
      contentName: fields.show,
      startTime: st,
      sports: fields.sports === "No" ? "false" : "true",
      league: fields.league,
      platform: fields.platform,
      contentLength: fields.contentLength,
      endTime: et,
      contentPicture: credentials.contentPicture,
      videoUrl: !!url ? url : fields.video,
      isCustom: isCustom,
      videoName: fields.videoName,
      gifs: isAllGif ? modalGif : [],
      shareDialogTitle: fields.dialogTitle,
      inviteText: fields.dialogText,
      shareDialogUrl: fields.dialogUrl,
      defaultGifKeyword: defaultKeyword,
    };

    if (postData["league"] === "") {
      delete postData["league"];
    }

    if (isAllGif || isAllGifEmpty) {
      addWatchParty(
        postData,
        (response) => {
          setSnackBarData({
            variant: response.status ? "success" : "error",
            message: response.msg,
          });
          setOpenSnackbar(true);
          history.push(ROUTES.WATCH_PARTY);
        },
        (error) => {
          setSnackBarData({
            variant: error.status ? "success" : "error",
            message: error.msg,
          });
          setOpenSnackbar(true);
        }
      );
    } else {
      console.log("Errrrororoor");
    }
  };

  useEffect(() => {
    if (fields.endTime !== null) {
      onChangeField("endTime", null);
    }
  }, [fields.startTime]);

  useEffect(() => {
    if (fields.startTime != null && fields.endTime != null) {
      let min = diff_minutes(fields.startTime, fields.endTime);
      onChangeField("contentLength", min);
    }
  }, [fields.endTime]);

  useEffect(() => {
    getLeagues(
      () => {},
      () => {}
    );
    getPlatforms(
      () => {},
      () => {}
    );
    getWatchPartyVideos(
      userToken,
      () => {},
      () => {}
    );
  }, []);

  useEffect(() => {
    if (editMode || cloneMode) {
      initialParty = { ...fields };
    }
  }, [fields]);

  const updateVideoData = (file, name) => {
    onChangeField("videoName", name);
    updateVideoFileData(file);
    updateIsCustom(true);
    updateRemoveVideoOption(false);
  };

  useEffect(() => {}, [fields]);

  return (
    <div className="container">
      {showNotFoundWatchPartyIdMessage ? (
        <div>Watch Party Id Not Found for Update</div>
      ) : (
        <>
          <DecisionPopup
            modalVisibility={iBarPopupVisible}
            // dialogContent={'IBAR'}
            dialogTitle={"IBAR"}
            confirmButtonTitle={"SAVE"}
            rejectButtonTitle={STRINGS.CANCEL}
            toggleDialogModal={() => setIbarPopupVisible(!iBarPopupVisible)}
            onConfirmation={(response) => {
              setIbarPopupVisible(false);
              setSnackBarData({
                variant: response.status ? "success" : "error",
                message: response.msg,
              });
              setOpenSnackbar(true);

              if (watchPartyForUpdate && watchPartyForUpdate._id) {
                getAnnouncementListing(watchPartyForUpdate._id);
              }
            }}
            watchPartyId={watchPartyForUpdate && watchPartyForUpdate._id}
            onRejection={() => setIbarPopupVisible(false)}
            dialogFields={{ edit: selectedAnnouncementId }}
          />
          <DecisionPopup
            modalVisibility={triviaPopupVisible}
            // dialogContent={'IBAR'}
            dialogTitle={"Trivia"}
            confirmButtonTitle={"SAVE"}
            rejectButtonTitle={STRINGS.CANCEL}
            toggleDialogModal={() => setTriviaPopupVisible(!triviaPopupVisible)}
            onConfirmation={(response) => {
              setSnackBarData({
                variant: response.status ? "success" : "error",
                message: response.msg,
              });
              setOpenSnackbar(true);
              setTriviaPopupVisible(false);
              if (watchPartyForUpdate && watchPartyForUpdate._id) {
                getAnnouncementListing(watchPartyForUpdate._id);
              }
            }}
            watchPartyId={watchPartyForUpdate && watchPartyForUpdate._id}
            onRejection={() => setTriviaPopupVisible(false)}
            dialogTriviaFields={{ edit: selectedAnnouncementId }}
          />
          <DecisionPopup
            modalVisibility={openDeleteAnnouncementPopup}
            dialogContent={`Are you sure you want to remove this user?`}
            dialogTitle={"Remove User"}
            confirmButtonTitle={STRINGS.CONFIRM}
            rejectButtonTitle={STRINGS.CANCEL}
            toggleDialogModal={() => setDeleteAnnouncementPopup(!openDeleteAnnouncementPopup)}
            onConfirmation={() => {
              deleteAnnouncement(
                selectedAnnouncementId._id,
                (success) => {
                  setSnackBarData({
                    variant: success.status ? "success" : "error",
                    message: success.msg,
                  });
                  setOpenSnackbar(true);
                  getAnnouncementListing(watchPartyForUpdate._id);
                },
                (failure) => {
                  setSnackBarData({
                    variant: failure.status ? "success" : "error",
                    message: failure.msg,
                  });
                  setOpenSnackbar(true);
                }
              );
              setDeleteAnnouncementPopup(false);
            }}
            onRejection={() => {
              setDeleteAnnouncementPopup(false);
            }}
          />
          <SnackbarWrapper
            visible={openSnackBar}
            onClose={() => setOpenSnackbar(false)}
            variant={snackbarData.variant}
            message={snackbarData.message}
          />
          <div className="content-panel">
            <div className="page-title">
              <h1>
                {!!editMode
                  ? PAGE_TITLES.EDIT_WATCH_PARTY
                  : !!cloneMode
                  ? PAGE_TITLES.CLONE_WATCH_PARTY
                  : PAGE_TITLES.ADD_NEW_WATCH_PARTY}
              </h1>
            </div>
            <div class="floating-form new_party_watch">
              <Form onSubmit={handleSubmit(onsubmit)}>
                <Collapsible trigger={"Media"} open={true}>
                  <div className="form-row">
                    <div className="col-md-6">
                      <div class="floating-label">
                        <Field
                          name={STRINGS.SHOW_NAME}
                          component={Input}
                          label={STRINGS.SHOW}
                          // placeholder={"Show"}
                          default={fields.show}
                          type={"text"}
                          onChange={(event) => onChangeField("show", event.target.value)}
                          config={{
                            value: fields.show ? fields.show : null,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="floating-label">
                        <Field
                          name={STRINGS.LEAGUE_NAME}
                          component={Select}
                          label={STRINGS.LEAGUE}
                          options={leagues}
                          value={selectedLeague}
                          // placeholder={"League"}
                          onChange={(value) => {
                            onChangeField("league", value.value);
                            setSelectedLeague(value.label);
                          }}
                          config={{
                            value: fields.league ? fields.league : null,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="floating-label">
                        <Field
                          name={STRINGS.PLATFORM_NAME}
                          component={Select}
                          options={platforms}
                          label={STRINGS.PLATFORM}
                          value={selectedPlatform}
                          // placeholder={"Platform"}
                          onChange={(value) => {
                            onChangeField("platform", value.value);
                            setSelectedPlatform(value.label);
                          }}
                          config={{
                            value: fields.platform ? fields.platform : null,
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div class="floating-label">
                        <Field
                          name={STRINGS.SPORTS_NAME}
                          component={Select}
                          // defaultMenuIsOpen={true}
                          options={[
                            { label: "Yes", value: true },
                            { label: "No", value: false },
                          ]}
                          value={selectedSport}
                          label={STRINGS.SPORTS}
                          // placeholder={"Sports"}
                          onChange={(value) => {
                            onChangeField("sports", value.label);
                            setSelectedSport(value.label);
                          }}
                          config={{
                            value: fields.sports ? (fields.sports == "Yes" ? true : false) : null,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Collapsible>
                <Collapsible trigger={"Time"} open={true}>
                  <div className="form-row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <div class="floating-label">
                          <Field
                            name={STRINGS.START_TIME}
                            component={KeyboardDateTimePickerr}
                            // placeholder={"Start Time"}
                            label={STRINGS.START}
                            minDate={new Date()}
                            minTime={new Date()}
                            defaultValue={fields.startTime}
                            onChangeDate={(value) => {
                              onChangeField("startTime", value);
                              onChangeForm("watchparty", "endTime", "");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div class="floating-label">
                        <Field
                          name={STRINGS.END_TIME}
                          component={KeyboardDateTimePickerr}
                          // placeholder={"End Time"}
                          label={STRINGS.END}
                          defaultValue={fields.endTime}
                          minDate={fields.startTime}
                          minTime={fields.startTime}
                          onChangeDate={(value) => {
                            onChangeField("endTime", value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className={`col-md-${editMode || cloneMode ? "4" : "6"}`}>
                      <div class="floating-label">
                        <Field
                          name={STRINGS.CONTENT_LENGTH}
                          component={Input}
                          label={STRINGS.CONTENT}
                          // placeholder={"Content Length"}
                          config={{
                            type: "number",
                            readOnly: true,
                            value: fields.contentLength ? fields.contentLength : null,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {(!!editMode || !!cloneMode) && (
                    <div style={{ display: "flex", flexDirection: "column" }} class="col-md-2">
                      <label>{STRINGS.SHOWN} </label>
                      <div className="css-yk16xz-control" style={{ alignItems: "center", borderWidth: 0 }}>
                        <Switch
                          checked={!fields.isHidden}
                          checkedIcon={false}
                          height={24}
                          onChange={(val) => onChangeField("isHidden", !val)}
                          onColor={"#64d2ff"}
                          uncheckedIcon={false}
                          width={48}
                        />
                      </div>
                    </div>
                  )}
                </Collapsible>
                <Collapsible trigger={"Design"} open={true}>
                  <div className="form-row">
                    <div className="col-md-6">
                      <div class="floating-label">
                        <Field
                          name={STRINGS.SHARE_DIALOG_TITLE_NAME}
                          component={Input}
                          label={STRINGS.SHARE_DIALOG_TITLE_LABEL}
                          // placeholder={"Title"}
                          default={fields.dialogTitle}
                          type={"text"}
                          onChange={(event) => onChangeField("dialogTitle", event.target.value)}
                          config={{
                            value: fields.dialogTitle ? fields.dialogTitle : null,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="floating-label">
                        <Field
                          name={"defaultGifResult"}
                          component={Input}
                          label={"Default Gif Results "}
                          // placeholder={"Title"}
                          default={""}
                          type={"text"}
                          onChange={(event) => onChangeField("gifResult", event.target.value)}
                          config={
                            {
                              // value: fields.dialogTitle
                              //   ? fields.dialogTitle
                              //   : null,
                            }
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-6">
                      <div class="floating-label">
                        <Field
                          name={STRINGS.INVITE_TEXT_NAME}
                          component={TextArea}
                          rows={2}
                          label={STRINGS.INVITE_TEXT_LABEL}
                          default={fields.dialogText}
                          // placeholder={"Invite text"}
                          onChange={(event) => onChangeField("dialogText", event.target.value)}
                          config={{
                            value: fields.dialogText ? fields.dialogText : null,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="floating-label">
                        <Field
                          name={STRINGS.SHARE_DIALOG_TITLE_URL_NAME}
                          component={TextArea}
                          rows={2}
                          label={STRINGS.SHARE_DIALOG_TITLE_LABEL}
                          default={fields.dialogUrl}
                          // placeholder={"Body"}
                          onChange={(event) => onChangeField("dialogUrl", event.target.value)}
                          config={{
                            value: fields.dialogUrl ? fields.dialogUrl : null,
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-6">
                      <div class="floating-label">
                        <Field
                          name={"primaryHexCode"}
                          component={Input}
                          label={"Primary Hex Code"}
                          // placeholder={"Title"}
                          default={fields.dialogTitle}
                          type={"text"}
                          onChange={(event) => onChangeField("primaryHexCode", event.target.value)}
                          config={
                            {
                              // value: fields.dialogTitle
                              //   ? fields.dialogTitle
                              //   : null,
                            }
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="floating-label">
                        <Field
                          name={"secondaryHexCode"}
                          component={Input}
                          label={"Secondary Hex Code"}
                          // placeholder={"Title"}
                          default={""}
                          type={"text"}
                          onChange={(event) => onChangeField("secondaryHexCode", event.target.value)}
                          config={
                            {
                              // value: fields.dialogTitle
                              //   ? fields.dialogTitle
                              //   : null,
                            }
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row add_video_col">
                    <div className="col-md-4">
                      <label>{STRINGS.WATCH_PARTY_VIDEO}</label>
                      {showRemoveVideoOption ? (
                        <div style={{ marginTop: "10px", marginBottom: "20px" }}>
                          <label style={{ color: "gray", marginRight: "20px" }}>{fields.videoName}</label>
                          <button
                            className="btn btn-sm btn-secondary"
                            onClick={() => {
                              setFields({
                                ...fields,
                                videoName: "",
                                video: "",
                              });
                              updateRemoveVideoOption(false);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      ) : null}

                      <div style={{ marginBottom: "10px", marginLeft: 10 }}>
                        <RadioButtons
                          handleValueChange={(value) => {
                            setSelectedWatchPartyVideoOption(value);
                            updateVideoFileData("");
                            setFields({
                              ...fields,
                              videoName: "",
                              video: "",
                            });
                            updateRemoveVideoOption(false);
                          }}
                          selectedValue={selectedWatchPartyVideoOption}
                          component={RadioButtons}
                          radioGroupItems={[
                            { label: "Select Video", value: "Select Video" },
                            { label: "Add Video", value: "Add Video" },
                          ]}
                        />
                      </div>
                      <div className="select_video">
                        {selectedWatchPartyVideoOption == "Select Video" ? (
                          <Field
                            name={"video name"}
                            component={Select}
                            options={watchPartyVideos}
                            value={selectedVideo}
                            placeholder={"Watch Party Video"}
                            onChange={(value) => {
                              setSelectedVideo(value.label);
                              setFields({
                                ...fields,
                                videoName: value.label,
                                video: value.value,
                              });
                              updateRemoveVideoOption(false);
                            }}
                            config={{
                              value: fields.video ? fields.video : null,
                            }}
                          />
                        ) : (
                          <Field
                            name="video"
                            component={CustomFileDrop}
                            acceptFiles={".webm,.MPG,.MP2,.MPEG,.MPE,.MPV,.mp4,.m4p,.m4v"}
                            updateVideoData={updateVideoData}
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-md-8">
                      <div className="form-row">
                        <div className={`col-md-12 form-group`}>
                          <div className={`form-row align-items-end justify-content-between`}>
                            <div className="col-md-10">
                              <label>{STRINGS.DEFAULT_KEYWORDS}</label>

                              <input
                                style={{ pointerEvents: "none" }}
                                className="form-control"
                                // placeholder="Add Default Keywords"
                                value={defaultKeyword}
                                onChange={() => {}}
                              ></input>
                            </div>
                            <div className={`col-md-2 btn_round`}>
                              <SelectGiphModal
                                btnLabel="Add Keyword"
                                setText={(value) => {
                                  set_defaultKeyword(value);
                                }}
                                defaultKeyword={defaultKeyword}
                                type="NonSelectGif"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 select_gif_icons">
                          <label>{STRINGS.WATCH_PARTY_VIDEO}</label>
                          <div className="col-md-12">
                            <div className="row align-items-center btn_space">
                              {modalGif.map((item, index) => {
                                return item.url ? (
                                  <div className="gif_image" key={index}>
                                    <img src={item.url} alt="gif" width={100} height={100} />
                                    <button
                                      className="btn_cross"
                                      type="button"
                                      onClick={() => {
                                        modalGif.map((item, i) => {
                                          if (i == index) {
                                            item.url = "";
                                            setModalGif((state) => [...state]);
                                          }
                                        });
                                      }}
                                    >
                                      <img src={require("../../../../assets/img/icons/cancel.png")}></img>
                                    </button>
                                  </div>
                                ) : (
                                  <SelectGiphModal
                                    key={index}
                                    id={index}
                                    btnLabel="Add"
                                    type="SelectGif"
                                    modalGif={modalGif}
                                    setModalGif={setModalGif}
                                    setText={set_searchGiphText}
                                    searchGiphText={searchGiphText}
                                  />
                                );
                              })}
                            </div>
                          </div>
                          {minGifError && <p className="error_msg text-danger">{minGifError}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                </Collapsible>
                {watchPartyForUpdate && watchPartyForUpdate._id ? (
                  <Collapsible trigger={"Interactive Bar"} open={true}>
                    <label className="ib_title">{"IBar Announcements"}</label>
                    <br />
                    <table className="table-collapse">
                      <thead>
                        <tr>
                          {IBAR_ANNOUNCEMENTS.map((ibar) => {
                            return <th>{ibar}</th>;
                          })}{" "}
                          <th>
                            <span
                              className="colpse_icon"
                              onClick={() => {
                                setIbarPopupVisible(true);
                                setselectedAnnouncementId(null);
                              }}
                            >
                              +
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody id="IBar-table">
                        {announcementList && announcementList.length > 0
                          ? announcementList.map((ibar, index) => {
                              return ibar.type == 1 ? (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{ibar.content.text}</td>
                                  <td>{ibar.redirect}</td>
                                  <td>{ibar.content.duration / 60}</td>
                                  <td>
                                    <EditOutlinedIcon
                                      onClick={() => {
                                        setselectedAnnouncementId(ibar);
                                        setIbarPopupVisible(true);
                                      }}
                                    />
                                    <DeleteForeverOutlinedIcon
                                      onClick={() => {
                                        setDeleteAnnouncementPopup(true);
                                        setselectedAnnouncementId(ibar);
                                      }}
                                    />
                                  </td>
                                </tr>
                              ) : null;
                            })
                          : "No Ibar Announcements found."}
                      </tbody>
                    </table>
                    <label className="ib_title">{"IBar Trivia"}</label>
                    <br />
                    <table className="table-collapse" id="trivia-table">
                      <thead>
                        <tr>
                          {IBAR_TRIVIA.map((ibar) => {
                            return <th>{ibar}</th>;
                          })}{" "}
                          <th>
                            <span
                              className="colpse_icon"
                              onClick={() => {
                                setselectedAnnouncementId(null);
                                setTriviaPopupVisible(true);
                              }}
                            >
                              +
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {iTriviaList && iTriviaList.length > 0
                          ? iTriviaList.map((ibar, index) => {
                              return ibar.type == 2 ? (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{ibar.content.text}</td>
                                  <td>{ibar.answer.text}</td>
                                  <td>{ibar.content.duration / 60}</td>
                                  <td>{ibar.answer.duration / 60}</td>
                                  <td>
                                    <div className="edit_del_icon">
                                      <EditOutlinedIcon
                                        onClick={() => {
                                          setselectedAnnouncementId(ibar);
                                          setTriviaPopupVisible(true);
                                        }}
                                      />
                                      <DeleteForeverOutlinedIcon
                                        onClick={() => {
                                          setDeleteAnnouncementPopup(true);
                                          setselectedAnnouncementId(ibar);
                                        }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ) : null;
                            })
                          : "No Trivia found."}
                      </tbody>
                    </table>
                  </Collapsible>
                ) : null}
                {!!editMode ? (
                  <Collapsible trigger={"Set Password"} open={true}>
                    <div className="form-row">
                      <div className="col-md-6">
                        <div class="floating-label">
                          <Field
                            name={STRINGS.SET_PASSWORD_NAME}
                            component={TextArea}
                            rows={2}
                            label={STRINGS.SET_PASSWORD_LABEL}
                            default={fields.password}
                            // placeholder={"Invite text"}
                            onChange={(event) => onChangeField("password", event.target.value)}
                            config={{
                              value: fields.password ? fields.password : null,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Collapsible>
                ) : null}
                <div className="btn_group d-flex justify-content-end mt-5" style={{ alignSelf: "right" }}>
                  <InputSubmit
                    buttonLabel={
                      !!editMode
                        ? PAGE_TITLES.EDIT_WATCH_PARTY
                        : !!cloneMode
                        ? PAGE_TITLES.CLONE_WATCH_PARTY
                        : PAGE_TITLES.ADD_WATCH_PARTY
                    }
                  />
                </div>
              </Form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const showForm = reduxForm({
  form: "watchparty",
  //onSubmitFail,
  validate: validator,
  enableReinitialize: true,
})(WatchPartyForm);

const mapStateToProps = (state, props) => {
  let path = props.history.location.pathname;
  let cloneMode = path == "/clone-watch-party";
  if (Object.keys(initialParty).length > 0) {
    let initialValues = {
      show: !!cloneMode ? "" : initialParty && initialParty.show,
      host: initialParty.host,
      sports: initialParty.sports,
      league: initialParty && initialParty.league,
      platform: initialParty && initialParty.platform,
      endTime: new Date(initialParty && initialParty.endTime),
      startTime: new Date(initialParty && initialParty.startTime),
      contentLength: initialParty && initialParty.contentLength,
      videoName: initialParty && initialParty.videoName,
      video: initialParty && initialParty.video,
      password: initialParty && initialParty.password,
    };
    return {
      initialValues: initialValues,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeForm: (form, field, value) => dispatch(onChangeForm(form, field, value)),
  };
};

export const Screen = connect(mapStateToProps, mapDispatchToProps)(showForm);
